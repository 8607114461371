import React from "react";
import Motorcycle from "../../../static/moto-senza-esame2.jpg";
const HeroMotorcycleNoExam = () => {
  return (
    <div className="relative">
      <img
        src={Motorcycle}
        className="absolute inset-0 object-cover w-full h-full"
        alt="Patente nautica con Autoscuola Faro a Pisa"
      />
      <div className="relative bg-gray-800 bg-opacity-75">
        <div className="absolute inset-x-0 bottom-0">
          <svg
            viewBox="0 0 224 12"
            fill="currentColor"
            className="w-full -mb-1 text-white"
            preserveAspectRatio="none"
          >
            <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
          </svg>
        </div>
        <div className="relative w-full sm:mx-auto sm:text-center py-48">
          <h1 className="p-4 sm:p-0 mb-6 font-sans  text-4xl font-extrabold tracking-tight text-white sm:text-6xl leading-8">
            Patente moto? <br className="hidden md:block" />
            Da oggi{" "}
            <span className="relative inline-block px-2  mt-4">
              <div className="absolute inset-0 transform -skew-x-12 bg-teal-accent-400" />
              <span className="relative text-teal-900">{"senza esame!"}</span>
            </span>{" "}
          </h1>
          <h2 className="p-4 sm:p-0 mt-6 mb-6 text-white font-roboto text-2xl font-semibold tracking-tight">
            Abbiamo semplificato il percorso per
            ottenere la patente. Da oggi anche in piccole e comode rate! <br />
            Sei pronto a solcare la strada? 
          </h2>
        </div>
      </div>
    </div>
  );
};

export default HeroMotorcycleNoExam;
