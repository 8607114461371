import React from "react";

const FeatureLiabilities = () => {
  return (
    <div className="bg-blue-accent-400 px-4 py-16 md:px-24 lg:px-8 lg:py-20">
      <div className="flex flex-col justify-center lg:max-w-screen-xl mx-auto">
        <div className="w-full mb-6">
          <h2 className="text-center mb-6 font-sans text-4xl font-extrabold tracking-tight
           text-white sm:text-5xl sm:leading-none">
            Scopri come ottenere la tua patente:
          </h2>
          <p className="text-center text-xl text-white">
            Ecco nel dettaglio l econdizioni per frequentare questo corso di
            formazione:
          </p>
        </div>
        <div className="grid gap-8 row-gap-8 sm:grid-cols-2 mt-12">
          <div className="bg-blue-100 rounded shadow-xl px-12 py-8">
            <div
              className="rounded-full bg-white flex items-center 
            justify-center w-20 h-20 mb-4 bg-indigo-50"
            >
              <svg
                className="rounded-full w-full h-full"
                width="303"
                height="303"
                viewBox="0 0 303 303"
                fill={"none"}
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="303" height="303" fill="white" />
                <path
                  d="M25.118 217L96.356 97.216H138.206L159.038 217H119.048L114.77 193.192H82.22L68.084 217H25.118ZM93.008 165.292H115.514L110.306 136.276L93.008 165.292ZM166.62 217L170.34 199.144C175.176 192.944 180.446 187.364 186.15 182.404C191.978 177.32 198.426 172.112 205.494 166.78C211.198 162.564 215.848 159.03 219.444 156.178C223.04 153.326 225.83 150.722 227.814 148.366C229.798 145.886 231.1 143.22 231.72 140.368C231.968 139.128 232.092 138.198 232.092 137.578C232.092 134.85 231.038 132.804 228.93 131.44C226.946 130.076 223.66 129.394 219.072 129.394C213.74 129.394 208.346 130.014 202.89 131.254C197.434 132.494 192.288 134.106 187.452 136.09L194.52 102.424C200.224 100.316 206.796 98.642 214.236 97.402C221.676 96.038 229.178 95.356 236.742 95.356C248.894 95.356 258.256 98.146 264.828 103.726C271.524 109.306 274.872 116.622 274.872 125.674C274.872 127.038 274.748 128.464 274.5 129.952C274.376 131.316 274.19 132.742 273.942 134.23C272.826 139.562 271.214 144.212 269.106 148.18C267.122 152.024 264.456 155.62 261.108 158.968C257.76 162.192 253.544 165.726 248.46 169.57C244.616 172.298 240.71 175.15 236.742 178.126C232.774 181.102 229.612 183.892 227.256 186.496H263.712L257.388 217H166.62Z"
                  fill="#2979ff"
                />
              </svg>
            </div>
            <h2 className="text-2xl mb-2 font-sans font-bold leading-5">
              Per ottenere la patente A2:
            </h2>
            <p className="text-xl text-gray-700 mt-4">
              il frequentante deve avere la patente A1 da almeno 2 anni (età
              minima 18 anni) e deve essere stata ottenuta tramite l'esame
              pratico della moto (non attraverso la patente B)
            </p>
          </div>
          <div className="bg-blue-100 rounded shadow-xl px-12 py-8">
            <div
              className="rounded-full bg-white flex items-center 
            justify-center w-20 h-20 mb-4 bg-indigo-50"
            >
              <svg
                className="rounded-full w-full h-full"
                width="303"
                height="303"
                viewBox="0 0 303 303"
                fill={"none"}
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="303" height="303" fill="white" />
                <path
                  d="M25.118 217L96.356 97.216H138.206L159.038 217H119.048L114.77 193.192H82.22L68.084 217H25.118ZM93.008 165.292H115.514L110.306 136.276L93.008 165.292ZM206.982 218.86C203.51 218.86 199.294 218.55 194.334 217.93C189.374 217.434 184.414 216.69 179.454 215.698C174.618 214.582 170.65 213.342 167.55 211.978L174.246 181.102C179.082 183.582 184.476 185.38 190.428 186.496C196.504 187.612 202.022 188.17 206.982 188.17C213.058 188.17 217.708 187.488 220.932 186.124C224.28 184.636 226.388 181.97 227.256 178.126C227.38 177.754 227.442 177.196 227.442 176.452C227.442 171.988 223.412 169.756 215.352 169.756H194.334L202.332 143.902H219.816C224.404 143.902 227.814 143.22 230.046 141.856C232.278 140.368 233.704 138.074 234.324 134.974C234.448 134.602 234.51 134.044 234.51 133.3C234.51 128.588 229.364 126.232 219.072 126.232C213.368 126.232 207.54 126.914 201.588 128.278C195.636 129.518 190.428 131.192 185.964 133.3L192.474 102.424C197.93 100.44 204.13 98.766 211.074 97.402C218.142 96.038 225.644 95.356 233.58 95.356C247.22 95.356 257.76 98.208 265.2 103.912C272.764 109.492 276.546 116.498 276.546 124.93C276.546 126.666 276.36 128.588 275.988 130.696C274.872 136.152 272.826 141.174 269.85 145.762C266.998 150.35 263.464 154.07 259.248 156.922C262.1 158.534 264.27 160.89 265.758 163.99C267.37 166.966 268.176 170.438 268.176 174.406C268.176 176.886 267.928 179.18 267.432 181.288C264.704 194.432 258.194 203.98 247.902 209.932C237.734 215.884 224.094 218.86 206.982 218.86Z"
                  fill="#2979ff"
                />
              </svg>
            </div>
            <h2 className="text-2xl mb-2 font-sans font-bold leading-7">
              Per ottenere la patente A3 (A senza limiti):
            </h2>
            <p className="text-xl text-gray-700 mt-4">
              il frequentante deve avere la patente A2 (marce) da almeno 2 anni
              (dunque l'età minima deve essere di 20 anni).
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureLiabilities;
