import React from "react";
import MotoLaw from "../../../static/moto-senza-esame3.jpg";

const FeatureModules2 = () => {
  return (
    <div className="bg-gray-900 relative flex flex-col-reverse py-16 lg:py-0 lg:flex-col">
      <div className=" w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:py-20 lg:max-w-screen-xl">
        <div className="mb-0 lg:max-w-lg lg:pr-8 xl:pr-6">
        <div className="max-w-xl mb-6">
            <h2 className="max-w-lg mb-6 font-sans text-4xl font-extrabold tracking-tight text-white sm:text-5xl sm:leading-none">
              Ecco cosa offre il nostro{" "}
              <span className=" text-blue-accent-400">
                corso di formazione:
              </span>
            </h2>
          </div>
          <div className="grid gap-8 row-gap-8 sm:grid-cols-1">
            <div>
              <h2 className="text-2xl mb-2 font-bold leading-5">
                <span className="relative inline-block px-2  mt-4">
                  <div className="absolute inset-0 transform -skew-x-12 bg-blue-accent-400" />
                  <span className="relative text-white font-sans font-extrabold text-2xl">
                    Modulo A
                  </span>
                </span>{" "}
              </h2>
              <p className="text-xl text-white">
                Preparazione e controllo tecnico del veicolo ai fini della
                sicurezza stradale; manovre particolari ai fini della sicurezza
                stradale (durata 3 ore anche nello stesso giorno).
              </p>
            </div>
            <div>
              <h2 className="text-2xl mb-2 font-bold leading-5">
                <span className="relative inline-block px-2  mt-4">
                  <div className="absolute inset-0 transform -skew-x-12 bg-blue-accent-400" />
                  <span className="relative text-white font-sans font-extrabold text-2xl">
                    Modulo B
                  </span>
                </span>{" "}
              </h2>
              <p className="text-xl text-white">
                Guida nel traffico: l'allievo deve essere in grado di eseguire
                operazioni in condizioni normali di traffico, in tutta sicurezza
                e adottando le opportune precauzioni (durata 4 ore in minimo due
                giorni).
              </p>
            </div>
          </div> 
        </div>
      </div>
      <div className="inset-y-0 top-0 right-0 w-full max-w-xl px-4 mx-auto mb-6 md:px-0 lg:pl-8 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-1/2 lg:max-w-full lg:absolute xl:px-0">
        <img
          className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
          src={MotoLaw}
          alt=""
        />
      </div>
    </div>
  );
};

export default FeatureModules2;
