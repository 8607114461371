import React from "react";
import MotoLaw from "../../../static/moto-senza-esame/moto-law.jpg";

const FeatureLaw = () => {
  return (
    <div className="my-24 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-center lg:order-first md:order-last order-last">
          <div className="max-w-xl mb-6">
            <h2 className="max-w-lg mb-6 font-sans text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl sm:leading-none">
              <span className=" text-blue-accent-400">Guida Senza Limiti:</span>{" "}
              <br className="block md:block sm:block" />
              Patente Moto in 7 Ore, Nessun Esame:
            </h2>
            <p className="text-xl text-gray-700 font-sans font-normal">
              Dal 18 settembre 2023, una nuova normativa elimina gli esami
              per ottenere la patente moto A2 o A. <br />
              Guidatori con almeno 2 anni
              di esperienza possono accedere a un corso di 7 ore e ottenere una
              patente di categoria superiore. <br />
              È un cambiamento epocale che
              semplifica l'accesso a moto più potenti e offre una maggiore
              libertà di guida.
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center lg:pl-8">
          <div className="flex flex-col items-end px-3 rounded-lg">
            <img src={MotoLaw} alt="" className="object-cover rounded-lg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureLaw;
