import React from 'react';
import Layout from '../components/layout/Layout';
import { ContactForm } from '../components/ContactForm';
import { Map } from '../components/Map';
import { HeroHeader } from '../components/HeroHeader';
import { FeatureContact } from '../components/FeatureContact';
import { ContactUs } from '../components/ContactUs';
import { contacts } from '../data/contacts/contacts';
import Seo from '../components/layout/SEO';
import HeroMotorcycleNoExam from '../components/patente-moto-senza-esame/HeroMotorcycleNoExam';
import FeatureLaw from '../components/patente-moto-senza-esame/FeatureLaw';
import FeatureLiabilities from '../components/patente-moto-senza-esame/FeatureLiabilities';
import FeatureModules from '../components/patente-moto-senza-esame/FeatureModules';
import FeatureMotorbikePricing from '../components/patente-moto-senza-esame/FeatureMotorbikePricing';
import ContactSection from '../components/home-page/ContactSection';
import FeatureModules2 from '../components/patente-moto-senza-esame/FeatureModules2';
import BannerCompass from '../components/BannerCompass';


const PatenteMotoSenzaEsame = () => {
  return (
    <Layout>
      <Seo
        title={"Patente Moto Senza Esame | Autoscuola Faro, Pisa"}
        description={"Prendi la patente Moto senza sostenere l'esame! Una novità offerta da Autoscuola Faro a Pisa"}
        keywords={[
          "Patente moto senza esame autoscuola Faro Pisa",
          "Patente moto autoscuola Faro Pisa",
          "Corsi patente moto autoscuola Faro Pisa"
        ]}
      />
      <HeroMotorcycleNoExam/>
      <FeatureLaw/>
      <FeatureLiabilities/>
      {/* <FeatureModules/> */}
      <FeatureModules2/>
      <FeatureMotorbikePricing/>
      <BannerCompass/>
      <ContactSection/>

    </Layout>
  )
};

export default PatenteMotoSenzaEsame;
